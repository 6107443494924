'use client';

import { useContext } from 'react';

import { UserContext, Context } from './provider';

export function useUser(): Context {
  const context = useContext(UserContext);

  if (!context) {
    return { isFetching: false, user: undefined };
  }

  return context;
}
