import { DonationTableParams, SingleDonationTableParams } from '@/shared/DonationTable/types';
import { Route } from 'next';

import deepMerge from './deepMerge';

type SubscriptionStatuses = 'active' | 'canceled' | 'all';

// Acts as a type guard to ensure that the route is valid
function isValidEndpoint<T extends string>(route: any): Route<T> {
  return route;
  // return route;
}

/**
 * These are typed with satisfies for a simpler interface, and will ensure the paths are valid
 * It does not check if the query params are valid
 */
const staticRoutes = {
  // onboarding: '/onboarding',
  // home: '/',
  // supporters: '/supporters',
  // donations: {
  //   payouts: '/donations/payouts',
  // },
  // reports: {
  //   new: '/reports/new',
  // },
  // fundraising: {
  //   campaigns: '/fundraising/campaigns',
  //   newCampaign: '/fundraising/campaigns/new',
  //   channels: '/fundraising/channels',
  //   goals: '/fundraising/goals',
  //   newGoal: '/fundraising/goals/new',
  //   templates: '/fundraising/templates',
  //   newTemplate: '/fundraising/templates/new',
  // },
  // integrations: {
  //   webhooks: '/integrations/webhook',
  //   newWebhook: '/integrations/webhook/new',
  // },
  // settings: {
  //   organizations: '/settings/organization',
  //   newOrganization: '/settings/organization/new',
  //   teamMembers: '/settings/team-members',
  //   inviteTeamMember: '/settings/team-members/invite',
  //   auditLogs: '/settings/logs',
  // },
} satisfies Record<string, Route | Record<string, Route>>;

/**
 * These are enforced with isValidEndpoint as typescript didn't get along with satisfies when using a function
 * Again, it does not check if the query params are valid
 */
const dynamicRoutes = {
  onboarding: '/onboarding' as Route,
  home: '/' as Route,
  supporters: '/supporters' as Route,
  reports: {
    new: '/reports/new' as Route,
  },
  supporter: (id: string) => isValidEndpoint(`/supporters/${id}`),
  donations: {
    payouts: '/donations/payouts' as Route,
    list: (params: DonationTableParams) => isValidEndpoint(`/donations?view=${params.view}&status=${params.status}`),
    donation: (id: string) => isValidEndpoint(`/donations/${id}`),
    subscriptions: (status: SubscriptionStatuses) => isValidEndpoint(`/subscriptions?status=${status}`),
    subscription: (id: string) => isValidEndpoint(`/subscriptions/${id}`),
  },
  fundraising: {
    campaigns: '/fundraising/campaigns' as Route,
    newCampaign: '/fundraising/campaigns/new' as Route,
    channels: '/fundraising/channels' as Route,
    goals: '/fundraising/goals' as Route,
    newGoal: '/fundraising/goals/new' as Route,
    templates: '/fundraising/templates' as Route,
    newTemplate: '/fundraising/templates/new' as Route,
    campaign: (id: string, status?: SingleDonationTableParams['status']) =>
      isValidEndpoint(`/fundraising/campaigns/${id}?status=${status || 'succeeded'}`),
    campaignInsights: (id: string) => isValidEndpoint(`/fundraising/campaigns/${id}/insights`),
    campaignSettings: (id: string) => isValidEndpoint(`/fundraising/campaigns/${id}/settings`),
    newChannel: (campaignId: string) => isValidEndpoint(`/fundraising/campaigns/${campaignId}/new`),
    channel: (campaignId: string, channelId: string, status?: SingleDonationTableParams['status']) =>
      isValidEndpoint(`/fundraising/campaigns/${campaignId}/channels/${channelId}?status=${status || 'succeeded'}`),
    channelQr: (campaignId: string, channelId: string) =>
      isValidEndpoint(`/fundraising/campaigns/${campaignId}/channels/${channelId}/qrcode`),
    channelInsights: (campaignId: string, channelId: string) =>
      isValidEndpoint(`/fundraising/campaigns/${campaignId}/channels/${channelId}/insights`),
    channelShare: (campaignId: string, channelId: string) =>
      isValidEndpoint(`/fundraising/campaigns/${campaignId}/channels/${channelId}/share`),
    channelSettings: (campaignId: string, channelId: string) =>
      isValidEndpoint(`/fundraising/campaigns/${campaignId}/channels/${channelId}/settings`),
    goal: (id: string) => isValidEndpoint(`/fundraising/goals/${id}`),
    template: (id: string) => isValidEndpoint(`/fundraising/templates/${id}`),
  },
  integrations: {
    webhooks: '/integrations/webhook' as Route,
    newWebhook: '/integrations/webhook/new' as Route,
    webhook: (id: string) => isValidEndpoint(`/integrations/webhook/${id}`),
  },
  settings: {
    organizations: '/settings/organization' as Route,
    newOrganization: '/settings/organization/new' as Route,
    teamMembers: '/settings/team-members' as Route,
    inviteTeamMember: '/settings/team-members/invite' as Route,
    auditLogs: '/settings/logs' as Route,
    organization: (id: string) => isValidEndpoint(`/settings/organization/${id}`),
    governance: (id: string) => isValidEndpoint(`/settings/organization/${id}/governance`),
    groups: (id: string) => isValidEndpoint(`/settings/organization/${id}/groups`),
    newGroup: (id: string) => isValidEndpoint(`/settings/organization/${id}/groups/new`),
    group: (orgId: string, groupId: string) => isValidEndpoint(`/settings/organization/${orgId}/group/${groupId}`),
    groupAccess: (orgId: string, groupId: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/group/${groupId}/access`),
    access: (id: string) => isValidEndpoint(`/settings/organization/${id}/access`),
    auditLog: (id: string) => isValidEndpoint(`/settings/logs/${id}`),
    teamMember: (id: string) => isValidEndpoint(`/settings/team-members/${id}`),
    person: (orgId: string, id: string) => isValidEndpoint(`/settings/organization/${orgId}/people/${id}`),
    newPerson: (id: string) => isValidEndpoint(`/settings/organization/${id}/people/new`),
    bankAccounts: (orgId: string) => isValidEndpoint(`/settings/organization/${orgId}/bank-accounts`),
    bankAccount: (orgId: string, id: string) => isValidEndpoint(`/settings/organization/${orgId}/bank-accounts/${id}`),
    newBankAccount: (orgId: string) => isValidEndpoint(`/settings/organization/${orgId}/bank-accounts/new`),
    paymentMethodDomains: (orgId: string) => isValidEndpoint(`/settings/organization/${orgId}/payment-method-domains`),
    newPaymentMethodDomain: (orgId: string) =>
      isValidEndpoint(`/settings/organization/${orgId}/payment-method-domains/new`),
  },
};

// COMBINED ROUTES
const routes = deepMerge(staticRoutes, dynamicRoutes);

export default routes;
