import { User } from '@/services/users/single';
import { useQuery } from '@tanstack/react-query';

export const GET_USER_KEY = 'user';

const useSingleUser = (id: string) =>
  useQuery<unknown, unknown, User | null>({
    queryFn: async () => (await fetch(`/api/users/${id}`)).json(),
    queryKey: [GET_USER_KEY],
    enabled: Boolean(id),
  });

export default useSingleUser;
