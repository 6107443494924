import Image from 'next/image';

import HeartSVG from './HeartLoadingAnimation.svg';

interface LoadingIconProps {
  filter: string;
}

const LoadingIcon = ({ filter }: LoadingIconProps) => {
  return (
    <div className="flex select-none items-center justify-center py-12">
      <Image src={HeartSVG} alt="Loading" className="h-10 w-auto" style={{ filter: filter }} priority />
    </div>
  );
};

type LoadingCellProps = {
  color?: 'white' | 'grey';
};

const LoadingCell = ({ color = 'grey' }: LoadingCellProps = {}) => {
  switch (color) {
    case 'grey':
      return (
        <LoadingIcon filter="invert(66%) sepia(8%) saturate(403%) hue-rotate(177deg) brightness(92%) contrast(89%)" />
      );
    case 'white':
    default:
      return <LoadingIcon filter="brightness(0) invert(1)" />;
  }
};

export default LoadingCell;
