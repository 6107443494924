'use client';

import useSingleUser from '@/requests/queries/singleUser';
import { User } from '@/services/users/single';
import { createContext } from 'react';
import { useMemo, useEffect } from 'react';

import { useAnalytics } from '@everfund/event-detective';

export type Context = {
  user?: User;
  isFetching: boolean;
};

export const UserContext = createContext<Context>({ user: undefined, isFetching: false });

type ProviderProps = {
  children: React.ReactNode;
  id?: string | null;
};

export function UserProvider({ children, id }: ProviderProps) {
  const { data: user, isFetching } = useSingleUser(id!);
  const { identify } = useAnalytics();

  const contextValue = useMemo(
    () => ({
      user: user?.id ? user : undefined,
      isFetching,
    }),
    [user, isFetching]
  );

  useEffect(() => {
    if (user) {
      identify({
        userId: user.id,
        traits: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          liveMode: user.liveMode,
          language: user.language,
          timeZone: user.timeZone,
        },
      });
    }
  }, [identify, user]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
